import { render, staticRenderFns } from "./ViewInfo.vue?vue&type=template&id=242f82a2&scoped=true"
import script from "./ViewInfo.vue?vue&type=script&lang=js"
export * from "./ViewInfo.vue?vue&type=script&lang=js"
import style0 from "./ViewInfo.vue?vue&type=style&index=0&id=242f82a2&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "242f82a2",
  null
  
)

export default component.exports